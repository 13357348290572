import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import {
  Navigate,
  Route, Routes
} from "react-router";
import { ReduxRouter } from "@lagunovsky/redux-react-router";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import { routes } from "./routes";

import GlobalResourceLoader from "./containers/GlobalResourceLoader";
import AuthWrapper from "./containers/AuthWrapper";
import App from "./containers/App";

// import StoreState from './components/StoreState';

class AppRoutes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { store, history } = this.props;

    const child =
      <AuthWrapper>
        <GlobalResourceLoader>
          <App routes={routes}>
            <Routes>
              {routes.map(r => {
                const RouteComponent = r.private ? Route : Route;
                const ChildComponent = r.component;
                const { exact = true } = r;

                return <Route element={<ChildComponent/>}
                  path={r.route}
                  key={r.route}/>;
              })}

              {/* No match route */}
              <Route element={<Navigate to="/404" />} />
            </Routes>
          </App>
        </GlobalResourceLoader>
      </AuthWrapper>;

    return (
      <>
        {!hasError
          && <Provider store={store}>
            <ReduxRouter history={history}>
              {child}
            </ReduxRouter>
          </Provider>
        }
        {!!hasError
          && <h1>An error was detected. Please refresh the website.</h1>}
      </>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
AppRoutes.propTypes = {
  store: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};
/* eslint-enable react/forbid-prop-types */

export default AppRoutes;
