import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Navigate } from "react-router";
import Tabs from "../../components/Tabs";
import EpgReports from "../../components/EpgReports";
import ExportBackups from "../../components/ExportBackups";
import NitReports from "../../components/NitReports";
import DvbiReports from "../../components/DvbiReports";
import { getCurrentLocation } from "../../selectors/routes";

const REPORTS_EPG = "epg-ingest";
const REPORTS_VIA = "via-export";
const REPORTS_VIA_V3 = "via-export-v3";
const REPORTS_NIT = "nit-reports";
const REPORTS_DVBI = "dvbi-reports";

const REPORTS = {
  [REPORTS_EPG]: true,
  [REPORTS_VIA]: true,
  [REPORTS_VIA_V3]: true,
  [REPORTS_NIT]: true,
  [REPORTS_DVBI]: true
};

function Reports(props) {
  const {
    currentTab
  } = props;

  if (!REPORTS[currentTab]) {
    return <Navigate to={`/reports/${REPORTS_EPG}`} />;
  }

  const tabs = [
    {
      name: <Link to={`/reports/${REPORTS_EPG}`}>EPG Ingest Reports</Link>,
      slug: REPORTS_EPG,
      currentClassName: "active"
    },
    {
      name: <Link to={`/reports/${REPORTS_VIA}`}>VIA Export Backups</Link>,
      slug: REPORTS_VIA,
      currentClassName: "active"
    },
    {
      name: <Link to={`/reports/${REPORTS_VIA_V3}`}>VIA Export Backups V3</Link>,
      slug: REPORTS_VIA_V3,
      currentClassName: "active"
    },
    {
      name: <Link to={`/reports/${REPORTS_NIT}`}>NIT Reports</Link>,
      slug: REPORTS_NIT,
      currentClassName: "active"
    },
    {
      name: <Link to={`/reports/${REPORTS_DVBI}`}>DVBI Reports</Link>,
      slug: REPORTS_DVBI,
      currentClassName: "active"
    }
  ];

  const tabsActions = [
  ];

  return (
    <div id="reports" className="page-content">
      <Tabs
        changeTab={() => {}}
        currentTab={currentTab}
        tabs={tabs}
        tabsActions={tabsActions}
      />

      { currentTab === REPORTS_EPG && <EpgReports />}
      { currentTab === REPORTS_VIA && <ExportBackups /> }
      { currentTab === REPORTS_VIA_V3 && <ExportBackups version={3} /> }
      { currentTab === REPORTS_NIT && <NitReports /> }
      { currentTab === REPORTS_DVBI && <DvbiReports /> }
    </div>
  );
}

Reports.propTypes = {
  currentTab: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  const route = getCurrentLocation(state).pathname.split("/");

  return {
    currentTab: route[route.length - 1] || REPORTS_EPG
  };
}

const mapDispatchToProps = { };

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
