import { getGridStringOperators } from "@mui/x-data-grid-pro";

export const customGridOperators = [
  ...getGridStringOperators(),
  {
    label: "Includes",
    value: "includes",
    getApplyFilterFn: filterItem => {
      if (!Array.isArray(filterItem.value) || !filterItem.value.length > 1) {
        return null;
      }

      return ({ value }) =>
        typeof value !== "undefined"
          && value !== null
          && filterItem.value.includes(value.toString());
    },
  },
];

export const METADATA_EXPORT_TARGETS = {
  ALL: { label: "All", value: "all_target" },
  VIA_EXPORT_V3: { label: "Via 3", value: "via3" },
  VIA_EXPORT: { label: "Via", value: "via" },
  NONE: { label: "None", value: "no_target" },
};
