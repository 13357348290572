import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography,
  Stack,
  Button,
} from "@mui/material";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import * as Sentry from "@sentry/react";

import useQueryAsState from "../../helpers/useQueryAsState";
import { addToast as addToastAction } from "../../actions/toasts";

import {
  indexQualifications as indexQualificationsAction,
  deleteQualification as deleteQualificationAction,
  updateQualification as updateQualificationAction
} from "../../actions/qualifications";
import {
  createTechChannel as createTechChannelAction,
  updateTechChannel as updateTechChannelAction
} from "../../actions/tech_channels";
import {
  updateTransponder as updateTransponderAction
} from "../../actions/transponders";

import { getCurrentLocation } from "../../selectors/routes";
import { listQualifications as listQualificationsSelector } from "../../selectors/qualifications";
import {
  listTechChannels as listTechChannelsSelector
} from "../../selectors/tech_channels";
import { listTransponders as listTranspondersSelector } from "../../selectors/transponders";
import { listOrbitalPositions as listOrbitalPositionsSelector } from "../../selectors/orbital_positions";
import { listProvidersWordings as listProvidersWordingsSelector } from "../../selectors/providers_wordings";

import { isLoading as isLoadingSelector } from "../../selectors/loaders";

import FullLoader from "../../components/FullLoader";
import DataTable from "../../components/DataTable";
import Tabs from "../../components/Tabs";
import FiltersInputs from "../../components/FiltersInputs";
import ProvidersWordings from "../../components/ProvidersWordings";
import QualificationModal from "../../components/QualificationModal/QualificationModal";
import QualificationAttributes from "../../components/QualificationAttributes";
import {
  capitalize,
  stringifyQualificationType,
  qualificationServiceName
} from "../../helpers/utils";

import { customGridOperators } from "../../utils/constant";

function Qualifications({
  isQualificationsLoading,
  currentTab,
  techChannels,
  qualifications,
  providersWordings,
  orbitalPositions,
  transponders,
  addToast,
  createTechChannel,
  updateTechChannel,
  deleteQualification,
  updateQualification,
  updateTransponder,
}) {
  const [currentQualificationsIds, setSelectedQualifications] = useState([]);
  const [modalOpened, toggleQualificationModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const [toReset, setToReset] = useState(false);
  const [queryData, setQueryData] = useQueryAsState({});

  useEffect(() => {
    if (queryData.orbital_position && orbitalPositions.length) {
      setQueryData({ "orbital_position_id": queryData.orbital_position });
      setFilters([{
        field: "orbital_position_id",
        value: queryData.orbital_position,
        operator: "equals",
        filterOperator: "equals",
        id: `orbital_position_id-${queryData.orbital_position}`,
        name: orbitalPositions.find(op => op.id === parseInt(queryData.orbital_position)).name
      }]);
    }
  }, [queryData]);

  useEffect(() => {
    if (currentTab !== "techchannel") {
      setFilters(filters.filter(f => f.field !== "attributes"));
    }
  }, [currentTab]);

  /* Proceed Modal */
  function openQualificationModal(currentQualificationsIds) {
    setToReset(false);
    if (currentQualificationsIds.length > 0) {
      setSelectedQualifications(currentQualificationsIds);
      toggleQualificationModal(true);
    }
  }

  const closeQualificationModal = (done = false) => {
    if (done) {
      setToReset(true);
    }
    toggleQualificationModal(false);
  };

  function isUniqDataTypeShown(selectedQualificationsIds, currentDataType) {
    const currentQualifications = qualifications.filter(q => selectedQualificationsIds.indexOf(q.id) !== -1);

    return currentQualifications.every(q => q.data_type === currentDataType);
  }

  function isUniqQualifTypeShown(selectedQualificationsIds, currentDataType, currentQualifType, validateElem = null) {
    const currentQualifications = qualifications.filter(q => selectedQualificationsIds.indexOf(q.id) !== -1);

    const uniqSelectedTypes = currentQualifications.reduce((acc, cur) => {
      if (acc.qualifTypes.indexOf(cur.qualification_type) === -1) {
        acc.qualifTypes.push(cur.qualification_type);
      }

      if (acc.dataTypes.indexOf(cur.data_type) === -1) {
        acc.dataTypes.push(cur.data_type);
      }

      return acc;
    }, { dataTypes: [], qualifTypes: [] });

    const isUniq = uniqSelectedTypes.dataTypes.length === 1
    && uniqSelectedTypes.dataTypes.indexOf(currentDataType) === 0
    && uniqSelectedTypes.qualifTypes.length === 1
    && uniqSelectedTypes.qualifTypes.indexOf(currentQualifType) === 0;

    if (!isUniq) {
      return false;
    }

    let isValidatedWithFunction = true;

    if (typeof validateElem === "function") {
      isValidatedWithFunction = currentQualifications.reduce((acc, cur) => {
        const isValidElem = validateElem(cur);

        return acc === false ? false : isValidElem;
      }, true);
    }

    return isValidatedWithFunction;
  }

  async function updateTechChannelsInformation(selectedQualifications) {
    if (selectedQualifications.length > 0) {
      const currentQualifications = qualifications.filter(q => selectedQualifications.indexOf(q.id) !== -1);
      const techChannelsIds = currentQualifications.map(q => q.data.id);

      if (!window.confirm(`Are you to update these technical channels: ${techChannelsIds.join(", ")} ?`)) {
        return;
      }

      let updated = 0;

      for (let i = 0; i < selectedQualifications.length; i += 1) {
        const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);
        const techChannel = techChannels.find(tc => tc.id === currentQualification.data.id);

        if (techChannel) {
          try {
            const techChannelParam = {
              service_name: currentQualification.data.service_name,
              scrambled: currentQualification.data.scrambled
            };

            await updateTechChannel(techChannel.id, techChannelParam);

            await deleteQualification(currentQualification.id);

            updated += 1;
          // eslint-disable-next-line no-empty
          } catch (_) {}
        }
      }

      if (updated === selectedQualifications.length) {
        addToast("success", "Success", "Technical channels has been successfully updated");
      } else if (updated === 0) {
        addToast("error", "Error", "Error while updating technical channels. Try again later");
      } else {
        addToast("warning", "Warning", "Some technical channel(s) cannot be updated. Try again.");
      }
    }
  }

  async function updateQualifications(selectedQualifications, data) {
    if (selectedQualifications.length > 0) {
      if (!window.confirm(`Are you sure to update these notifications: ${selectedQualifications.join(", ")} ?`)) {
        return;
      }
    }

    let processed = 0;
    for (let i = 0; i < selectedQualifications.length; i += 1) {
      const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);

      try {
        await updateQualification(currentQualification.id, data);

        processed += 1;
      } catch (_) {}
    }
  }

  async function dismissQualifications(selectedQualifications) {
    if (selectedQualifications.length > 0) {
      if (!window.confirm(`Are you sure to dismiss these notifications: ${selectedQualifications.join(", ")} ?`)) {
        return;
      }

      let processed = 0;

      for (let i = 0; i < selectedQualifications.length; i += 1) {
        const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);
        const transponder = transponders.find(t => t.id === currentQualification.data.transponder_id);

        if (transponder) {
          if (Array.isArray(currentQualification.data.service_name)) {
            Sentry.captureMessage("service_name is an array");
            currentQualification.data.service_name = currentQualification.data.service_name[0];
            currentQualification.data.onid = currentQualification.data.onid[0];
            currentQualification.data.sid = currentQualification.data.sid[0];
            currentQualification.data.tsid = currentQualification.data.tsid[0];
          }
          const techChannelParam = {
            transponder_id: currentQualification.data.transponder_id,
            service_name: currentQualification.data.service_name || qualificationServiceName(transponder, currentQualification.data),
            tech_channel_type: "dvb",
            scrambled: currentQualification.data.scrambled,
            onid: transponder.theoretical_onid,
            tsid: transponder.theoretical_tsid,
            sid: currentQualification.data.sid,
            status: "discarded"
          };

          try {
            const { isSuccess } = await createTechChannel(techChannelParam);

            if (!isSuccess) {
              throw new Error();
            }

            await deleteQualification(currentQualification.id);

            processed += 1;
          // eslint-disable-next-line no-empty
          } catch (_) {}
        }
      }

      if (processed === selectedQualifications.length) {
        addToast("success", "Success", "Notifications has been successfully dismissed");
      } else if (processed === 0) {
        addToast("error", "Error", "Error while dismissed the notification. Try again later");
      } else {
        addToast("warning", "Warning", "Some notification(s) cannot be deleted. Try again.");
      }
    }
  }

  async function dismissDeletionQualifications(selectedQualifications) {
    if (selectedQualifications.length > 0) {
      if (!window.confirm(`Are you sure to dismiss these notifications: ${selectedQualifications.join(", ")} ?`)) {
        return;
      }

      let processed = 0;

      for (let i = 0; i < selectedQualifications.length; i += 1) {
        const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);

        try {
          await deleteQualification(currentQualification.id);

          processed += 1;
        // eslint-disable-next-line no-empty
        } catch (_) {}
      }

      if (processed === selectedQualifications.length) {
        addToast("success", "Success", "Notifications has been successfully dismissed");
      } else if (processed === 0) {
        addToast("error", "Error", "Error while dismissed the notification. Try again later");
      } else {
        addToast("warning", "Warning", "Some notification(s) cannot be dismissed. Try again.");
      }
    }
  }

  async function createPendingTechnicalChannel(selectedQualifications) {
    if (selectedQualifications.length > 0) {
      let processed = 0;

      for (let i = 0; i < selectedQualifications.length; i += 1) {
        const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);
        const transponder = transponders.find(t => t.id === currentQualification.data.transponder_id);

        if (transponder) {
          const techChannelParam = {
            transponder_id: currentQualification.data.transponder_id,
            service_name: currentQualification.data.service_name || qualificationServiceName(transponder, currentQualification.data),
            tech_channel_type: "dvb",
            scrambled: currentQualification.data.scrambled,
            onid: transponder.theoretical_onid,
            tsid: transponder.theoretical_tsid,
            sid: currentQualification.data.sid,
            status: "pending"
          };

          try {
            const { isSuccess } = await createTechChannel(techChannelParam);

            if (!isSuccess) {
              throw new Error();
            }

            await deleteQualification(currentQualification.id);

            processed += 1;
          // eslint-disable-next-line no-empty
          } catch (_) {}
        }
      }

      if (processed === selectedQualifications.length) {
        addToast("success", "Success", "Pending technical channels has been successfully created");
      } else if (processed === 0) {
        addToast("error", "Error", "Error while creating technical channels. Try again later");
      } else {
        addToast("warning", "Warning", "Some technical channel(s) cannot be created. Refresh the page and try again.");
      }
    }
  }

  async function processTransponderQualifications(selectedQualifications, actionType) {
    if (actionType === "update") {
      if (selectedQualifications.length > 0) {
        const currentQualifications = qualifications.filter(q => selectedQualifications.indexOf(q.id) !== -1);
        const transponderIds = currentQualifications.map(q => q.data.transponder_id);

        if (!window.confirm(`Are you to update these transponders: ${transponderIds.join(", ")} ?`)) {
          return;
        }

        let updated = 0;

        for (let i = 0; i < selectedQualifications.length; i += 1) {
          const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);
          const transponder = transponders.find(tc => tc.id === currentQualification.data.transponder_id);

          if (transponder) {
            try {
              await updateTransponder(transponder.id, currentQualification.data);

              await deleteQualification(currentQualification.id);

              updated += 1;
            // eslint-disable-next-line no-empty
            } catch (_) {}
          }
        }

        if (updated === selectedQualifications.length) {
          addToast("success", "Success", "Transponders has been successfully updated");
        } else if (updated === 0) {
          addToast("error", "Error", "Error while updating transponders. Try again later");
        } else {
          addToast("warning", "Warning", "Some transponder(s) cannot be updated. Try again.");
        }
      }
    } else if (actionType === "deletion") {
      if (selectedQualifications.length > 0) {
        const currentQualifications = qualifications.filter(q => selectedQualifications.indexOf(q.id) !== -1);
        const transponderIds = currentQualifications.map(q => q.data.transponder_id);

        // Check every transponder can be deleted
        let isValid = true;

        for (let i = 0; i < transponderIds.length; i += 1) {
          const transponderId = transponderIds[i];
          const activeTechChannels = techChannels.filter(tc => tc.status === "active" && tc.transponder_id === transponderId);

          if (activeTechChannels.length > 0) {
            isValid = false;
          }
        }

        if (isValid) {
          if (!window.confirm(`Are you to update these transponders: ${transponderIds.join(", ")} ?`)) {
            return;
          }

          let updated = 0;

          for (let i = 0; i < selectedQualifications.length; i += 1) {
            const currentQualification = qualifications.find(q => q.id === selectedQualifications[i]);
            const transponder = transponders.find(tc => tc.id === currentQualification.data.transponder_id);

            if (transponder) {
              try {
                await updateTransponder(transponder.id, currentQualification.data);

                await deleteQualification(currentQualification.id);

                updated += 1;
              // eslint-disable-next-line no-empty
              } catch (_) {}
            }
          }

          if (updated === selectedQualifications.length) {
            addToast("success", "Success", "Transponders has been successfully updated");
          } else if (updated === 0) {
            addToast("error", "Error", "Error while updating transponders. Try again later");
          } else {
            addToast("warning", "Warning", "Some transponder(s) cannot be updated. Try again.");
          }
        } else {
          window.alert("Cannot delete selected transponder(s): one or more of them own active technical channel(s)");
        }
      }
    }
  }

  const nullProvidersWordings = providersWordings.filter(pw => !pw.genre_id);
  const qualifTypes = [];

  const qualifByType = qualifications.reduce((acc, qualif) => {
    const qualifType = qualif.data_type.toLowerCase();

    if (qualifTypes.indexOf(qualifType) === -1) {
      qualifTypes.push(qualifType);
    }

    if (Object.keys(acc).indexOf(qualifType) === -1) {
      acc[qualifType] = [];
    }

    if (qualifType === "techchannel") {
      const techChannel = techChannels.find(tc => tc.id === qualif.data.id);
      acc[qualifType].push(techChannel
        ? {
          ...qualif,
          attributes: {
            scrambled: qualif.data?.scrambled ?? techChannel.scrambled,
            service_name: qualif.data?.service_name ?? techChannel.service_name
          }
        }
        : qualif);
    } else {
      acc[qualifType].push(qualif);
    }

    return acc;
  }, {});

  const tabs = [
    {
      name: <Link to="/notifications/all">All (without Wordings)</Link>,
      slug: "all",
      currentClassName: "active"
    },
    ...qualifTypes.map(type => ({
      name:
        <Link to={`/notifications/${type}`}>
          {`${capitalize(stringifyQualificationType(type))}s `}
          <span className="tab-nav-number">
            (
            {qualifByType[type].length}
            )
          </span>
        </Link>,
      slug: type.toLowerCase(),
      currentClassName: "active"
    })),
    {
      name:
        <Link to="/notifications/providers_wordings">
          {"Provider Wordings "}
          <span className="tab-nav-number">
            (
            {nullProvidersWordings.length}
            )
          </span>
        </Link>,
      slug: "providers_wordings",
      currentClassName: "active"
    }
  ];

  // eslint-disable-next-line no-prototype-builtins
  const qualifsToShow = currentTab !== "all" && qualifByType.hasOwnProperty(currentTab) ? qualifByType[currentTab] : qualifications;
  const uniqueQualificationTypes = qualifsToShow
    .reduce((acc, cur) => {
      if (!acc.find(q => q.value === cur.qualification_type)) {
        acc.push({
          name: cur.qualification_type,
          value: cur.qualification_type
        });
      }

      return acc;
    }, [])
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }

      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

  const primaryFilters = [
    {
      text:
        <>
          <FontAwesomeIcon className="orange-icon" icon={faExclamationCircle} />
          &nbsp;
          Transfer List
        </>,
      name: "transferlist",
      values: [{ name: "In transfer list", value: true }, { name: "Not in transfer list", value: false }]
    },
    {
      text: "Orbital Position",
      name: "orbital_position_id",
      values: orbitalPositions.map(op => ({ name: op.name, value: op.id }))
    },
    {
      text: "Action Type",
      name: "qualification_type",
      values: uniqueQualificationTypes
    },
    ...currentTab === "techchannel"
      ? [{
        text: "Attributes",
        name: "attributes",
        values: [
          { name: "Scrambled", value: "scrambled" },
          { name: "Test Service Name", value: "test_service_name" }
        ]
      }]
      : []
  ];

  const tableActions = [
    {
      component: key => <Dropdown disabled={!currentQualificationsIds.length} text="Actions" key={key}>
        <Dropdown.Menu className="right-menu">
          {isUniqQualifTypeShown(currentQualificationsIds, "TechChannel", "Creation")
              && <>
                <Dropdown.Item className="red" text="Dismiss selected notifications" onClick={() => dismissQualifications(currentQualificationsIds)} />
                <Dropdown.Item className="orange" text="Create pending technical service(s)" onClick={() => createPendingTechnicalChannel(currentQualificationsIds)} />
              </>
          }
          {isUniqQualifTypeShown(currentQualificationsIds, "TechChannel", "Update", qualification => {
            const techChannel = techChannels.find(tc => tc.id === qualification.data.id);

            return qualification.transferlist === false && techChannel && techChannel.status === "active";
          })
              && <Dropdown.Item className="green" text="Update information of selected notifications" onClick={() => updateTechChannelsInformation(currentQualificationsIds)} />
          }
          {isUniqQualifTypeShown(currentQualificationsIds, "TechChannel", "Deletion")
              && <Dropdown.Item className="red" text="Dismiss selected notifications" onClick={() => dismissDeletionQualifications(currentQualificationsIds)} />
          }
          {isUniqQualifTypeShown(currentQualificationsIds, "Transponder", "Update")
              && <Dropdown.Item className="green" text="Update information of selected notifications" onClick={() => processTransponderQualifications(currentQualificationsIds, "update")} />
          }
          {isUniqQualifTypeShown(currentQualificationsIds, "Transponder", "Deletion")
              && <>
                <Dropdown.Item className="red" text="Delete selected transponders" onClick={() => processTransponderQualifications(currentQualificationsIds, "deletion")} />
              </>
          }
          {isUniqDataTypeShown(currentQualificationsIds, "Transponder")
              && <>
                <Dropdown.Item className="red" text="Dismiss selected notifications" onClick={() => dismissDeletionQualifications(currentQualificationsIds)} />
                <Dropdown.Item className="red" text="Archive selected notifications" onClick={() => updateQualifications(currentQualificationsIds, { discarded: true })} />
              </>
          }
        </Dropdown.Menu>
      </Dropdown>
    },
    {
      component: key => <Button
        key={key || "all"}
        variant="outlined"
        onClick={() => openQualificationModal(qualifsToShow.map(q => q.id))}
      >
        Process all
      </Button>
    },
    {
      component: key => <Button
        key={key || "selected"}
        disabled={!currentQualificationsIds.length}
        variant="contained"
        color="secondary"
        onClick={() => openQualificationModal(currentQualificationsIds)}
      >
        Process selected
      </Button>
    },
  ];

  return (
    <div id="qualifications" className="page-content">
      { isQualificationsLoading
        ? <FullLoader />
        : <>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
            <Tabs
              changeTab={() => {}}
              currentTab={currentTab}
              tabs={tabs}
            />
            <Link to="/archives">
              <Typography variant="body3" color="text.primary" sx={{ textDecoration: "underline" }}>Voir les archives</Typography>
            </Link>
          </Stack>

          {currentTab !== "providers_wordings"
            && <>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start" sx={{ mb: 1 }}>
                <FiltersInputs
                  primaryFilters={primaryFilters}
                  defaultFilters={filters}
                  onFiltersChange={setFilters}
                />
                <Stack direction="row" spacing={1}>
                  { tableActions.map((ta, i) => ta.component(i)) }
                </Stack>
              </Stack>
              <div style={{ height: "calc(100vh - 270px)", minHeight: "160px" }}>
                <DataTable
                  disableRowClick
                  pagination
                  filters={filters}
                  checkboxSelection
                  cols={[
                    {
                      headerName: "TL?",
                      field: "transferlist",
                      sortable: true,
                      renderCell: params => params.value ? <FontAwesomeIcon className="orange-icon" icon={faExclamationCircle} /> : "",
                      filterOperators: customGridOperators
                    }, {
                      headerName: "Id",
                      field: "id",
                      sortable: true,
                    }, {
                      headerName: "Orbital position",
                      field: "orbital_position_id",
                      sortable: true,
                      renderCell: params => params.value ? orbitalPositions.find(op => op.id === params.value).name : "",
                      filterOperators: customGridOperators
                    }, {
                      headerName: "Object",
                      field: "data_type",
                      sortable: true,
                      renderCell: params => params.value ? capitalize(stringifyQualificationType(params.value)) : "",
                    }, {
                      headerName: "Action Type",
                      field: "qualification_type",
                      sortable: true,
                      filterOperators: customGridOperators
                    }, {
                      headerName: "Object Attributes",
                      field: "attributes",
                      flex: 1,
                      renderCell: ({ row }) => <QualificationAttributes qualif={row} techChannels={techChannels} transponders={transponders} />,
                      filterOperators: [
                        {
                          label: "Includes",
                          value: "includes",
                          getApplyFilterFn: filterItem => {
                            if (!Array.isArray(filterItem.value) || !filterItem.value.length) {
                              return null;
                            }
                            return ({ value }) => (filterItem.value.includes("scrambled") && !!value?.scrambled)
                                || (filterItem.value.includes("test_service_name") && value?.service_name === "Test");
                          }
                        },
                      ],
                    }, {
                      headerName: "Date",
                      field: "updated_at",
                      sortable: true,
                    }
                  ]}
                  onRowSelectionModelChange={setSelectedQualifications}
                  data={qualifsToShow}
                  toReset={toReset}
                  selectionToReset={currentQualificationsIds}
                />
              </div>
            </>
          }

          {currentTab === "providers_wordings" && <ProvidersWordings />}
        </>
      }

      <QualificationModal
        currentQualificationsIds={currentQualificationsIds}
        modalOpened={modalOpened}
        closeModal={closeQualificationModal}
      />
    </div>
  );
}

Qualifications.propTypes = {
  isQualificationsLoading: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,

  techChannels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  qualifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providersWordings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orbitalPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transponders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

  addToast: PropTypes.func.isRequired,
  createTechChannel: PropTypes.func.isRequired,
  updateTechChannel: PropTypes.func.isRequired,
  deleteQualification: PropTypes.func.isRequired,
  updateQualification: PropTypes.func.isRequired,
  updateTransponder: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const routes = getCurrentLocation(state).pathname.split("/");

  return {
    techChannels: listTechChannelsSelector(state),
    qualifications: listQualificationsSelector(state, { discarded: false }),
    providersWordings: listProvidersWordingsSelector(state),
    isQualificationsLoading: isLoadingSelector(state, indexQualificationsAction.toString())
                              || isLoadingSelector(state, createTechChannelAction.toString())
                              || isLoadingSelector(state, updateTechChannelAction.toString())
                              || isLoadingSelector(state, deleteQualificationAction.toString()),
    currentTab: routes[routes.length - 1] && routes[routes.length - 1] !== "notifications" ? routes[routes.length - 1] : "all",
    orbitalPositions: listOrbitalPositionsSelector(state),
    transponders: listTranspondersSelector(state)
  };
}

const mapDispatchToProps = {
  addToast: addToastAction,
  createTechChannel: createTechChannelAction,
  updateTechChannel: updateTechChannelAction,
  deleteQualification: deleteQualificationAction,
  updateQualification: updateQualificationAction,
  updateTransponder: updateTransponderAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Qualifications);
