import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { convertISOToDate } from "../../helpers/utils";
import { indexDvbiReports as indexDvbiReportsAction } from "../../actions/dvbi_reports";

class DvbiReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paginationModel: {
        page: 0,
        pageSize: 25
      }
    };
  }

  componentDidMount() {
    const { paginationModel } = this.state;

    this.loadDvbiReports(paginationModel.page + 1, paginationModel.pageSize);
  }

  loadDvbiReports(page, pageSize) {
    const { indexDvbiReports, isDvbiReportsLoading } = this.props;

    if (!isDvbiReportsLoading) {
      indexDvbiReports(page, pageSize);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  goToBucketFolder(item) {
    if (!item?.folder || !item.bucket_name) {
      alert("Unable to generate link to report");
      return;
    }

    window.open(
      `https://s3.console.aws.amazon.com/s3/buckets/${item.bucket_name}?region=eu-west-1&prefix=${item.folder}/&showversions=false`,
      "_blank"
    );
  }

  handlePaginationModelChange = ({ page, pageSize }) => {
    const { paginationModel } = this.state;

    this.setState({ paginationModel: {
      page: pageSize !== paginationModel.pageSize ? 0 : page,
      pageSize: pageSize
    } }, () => {
      this.loadDvbiReports(this.state.paginationModel.page + 1, this.state.paginationModel.pageSize);
    });
  };

  render() {
    const { isDvbiReportsLoading, dvbiReports } = this.props;
    const { paginationModel } = this.state;

    const columns = [
      {
        field: "download",
        headerName: "Download",
        renderCell: params =>
          <FontAwesomeIcon
            icon={faDownload}
            onClick={() => this.goToBucketFolder(params.row)}
          />,
        sortable: false,
        flex: 1,
      },
      {
        field: "orbital_position",
        headerName: "Orbital Position",
        sortable: false,
        flex: 1,
      },
      {
        field: "folder",
        headerName: "Folder",
        sortable: false,
        flex: 1,
      },
      {
        field: "started_at",
        headerName: "Date",
        sortable: false,
        flex: 1,
        valueGetter: params => convertISOToDate(params.value),
      },
    ];

    return (
      <DataGridPro
        loading={isDvbiReportsLoading}
        columns={columns}
        rows={dvbiReports.dvbiReports}
        rowCount={dvbiReports.dvbiReportsCount}
        disableColumnFilter={true}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={this.handlePaginationModelChange}
        onRowClick={params => {
          this.goToBucketFolder(params.row);
        }}
      />
    );
  }
}

DvbiReports.propTypes = {
  dvbiReports: PropTypes.exact({
    dvbiReports: PropTypes.array.isRequired,
    dvbiReportsCount: PropTypes.number.isRequired
  }).isRequired,
  indexDvbiReports: PropTypes.func.isRequired,
  isDvbiReportsLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    dvbiReports: state.dvbiReports,
    isDvbiReportsLoading: !!state.loaders.actions.find(a => a === indexDvbiReportsAction.toString())
  };
}

const mapDispatchToProps = {
  indexDvbiReports: indexDvbiReportsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(DvbiReports);
