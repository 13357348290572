import { handleActions } from "redux-actions";

const initialState = {
  dvbiReports: [],
  dvbiReportsCount: 0
};

export default handleActions({
  STORE_DVBI_REPORTS: (state, action) => ({
    ...state,
    dvbiReports: action.payload.result,
    dvbiReportsCount: parseInt(action.payload.headers["total-count"])
  })
}, initialState);
